import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../components/CustomSignUp"
import { updateProject } from "../../../graphql/mutations"

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import SEO from "../../../components/seo"
import awsExports from "../../../aws-exports"
import { Hub } from "aws-amplify"
import { listProjects } from "../../../graphql/queries"
import { onUpdateRoom } from "../../../graphql/subscriptions"

import AdminMenu from "../../../components/AdminMenu"

import axios from "axios"

Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const ApprovalPage = () => {
  const [user, setUser] = useState(null)
  const [projects, setProjects] = useState([])
  const [roomCount, setRoomCount] = useState(1)

  useEffect(() => {
    if (!user) {
      getUser()
    }
    fetchProjects()
    const subscription = API.graphql(graphqlOperation(onUpdateRoom)).subscribe({
      next: room => {
        return
      },
      // console.log("room update!: ", room),
    })
    // console.log("roomcount: ", roomCount)
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      // console.log("user: ", user)
      //check for user pool group
      // console.log(
      //   "User Pool Group: ",
      //   user.signInUserSession.accessToken.payload["cognito:groups"]
      // )
      // console.log("attributes: ", user.attributes)
      // console.log(
      //   "Is Admin: ",
      //   user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      //     "Admins"
      //   )
      // )
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        // console.log("user signed in")
        getUser()
        break
      case "signUp":
        // console.log("user signed up")
        break
      case "signOut":
        // console.log("user signed out")
        break
      case "signIn_failure":
        // console.log("user sign in failed")
        break
      case "configured":
        // console.log("the Auth module is configured")
    }
  })

  async function fetchProjects() {
    try {
      const projectData = await API.graphql(
        graphqlOperation(listProjects, {
          filter: { approved: { ne: true } },
          limit: 1000,
        })
      )
      const projects = projectData.data.listProjects.items
      await Promise.all(
        projects.map(async project => {
          if (project.image) {
            const image = await Storage.get(project.image)
            project.image = image
          }
          return project
        })
      )
      setProjects(projects)
      // console.log("projects: ", projects)
    } catch (err) {
      console.log("error fetching projects: ", err)
    }
  }

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      // console.log("running handle auth change")
      getUser()
    }
  }

  const ProjectList = props => {
    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)
    if (
      props.authState === "signedIn" &&
      user &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admins"
      )
    ) {
      if (projects.length > 0) {
        return (
          <div>
            <AdminMenu />
            <section>
              {projects.map((project, index) => (
                <div
                  key={project.id ? project.id : index}
                  style={{
                    border: "1px solid black",
                    margin: "1rem",
                  }}
                >
                  <ul>
                    <li>Title: {project.title}</li>
                    <li>Abstract: {project.subtitle}</li>
                    <li>Description: {project.description}</li>
                    <li>Scene ID: {project.sceneUrl}</li>
                    {project.image && (
                      <li>
                        <img src={project.image} style={{ width: 300 }} />
                      </li>
                    )}
                    {project.location && (
                      <li>
                        Location: Lon: {project.location.lon}, Lat:{" "}
                        {project.location.lat}
                      </li>
                    )}
                    <li>On Hubs: {project.onHubs}</li>
                    <li>Website: {project.website}</li>
                    <li>Email: {project.email}</li>
                    <li>Owner ID: {project.ownerId}</li>
                    <li>Owner Name: {project.ownerName}</li>
                    <li>Artist Bio: {project.ownerBio}</li>
                    <li>
                      Mobile Friendly: {project.mobileFriendly ? "Yes" : "No"}
                    </li>
                    <li>Approved: {project.approved ? "Yes" : "No"}</li>
                    <li>Featured: {project.featured ? "Yes" : "No"}</li>
                  </ul>
                  <div>
                    <button onClick={() => approveProject(project.id)}>
                      Approve
                    </button>
                  </div>
                </div>
              ))}
            </section>
          </div>
        )
      } else {
        return <Link to="/partner/submit">Submit a project</Link>
      }
    } else return <div>You don't have permission to view this page. </div>
  }

  async function approveProject(id) {
    try {
      const project = { id: id, approved: true }
      // console.log("project: ", project)
      const approvedProject = await API.graphql(
        graphqlOperation(updateProject, { input: project })
      )
      // console.log("deleted project: ", approvedProject)
      // const newProjectState = projects.filter(project => project.)
      fetchProjects()
    } catch (err) {
      console.log("error approving project:", err)
    }
  }

  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        >
          <ProjectList />

          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Admin Dashboard | Approve Projects" />
      <Link to="/">Go back to the homepage</Link>
    </section>
  )
}

export default ApprovalPage
